export enum AuthRoutePath {
  LOGIN = '/auth/login',
  REGISTER = '/auth/register',
}

export enum AppRoutePath {
  HOME = '/app/home',
  PROFILE = '/app/profile',
  AUDIT = '/app/audit',
  AUDITDETAILS = '/app/audit/details/:cliente_id',
  VALIDATION = '/app/validation',
  VALIDATIONDETAIL = '/app/validation/:validationId',
  USERS = '/app/users',
  CONSULTANCY = '/app/consultancy',
  FARMERS = '/app/farmers',
  CTV = '/app/ctv',
  GROWCROPS = '/app/gorwcrops',
  AUDITFARMER = '/app/audit/farmer/:cliente_id',
  CLIENTS = '/app/clients',
  CLIENTDETAIL = '/app/client/:cliente_id',
  ENLARGEREGION = '/app/enlargeregion',
  MICROREGION = '/app/microregion',
  CONSULTANCYDETAIL = '/app/consultancy/:consultancyId',
  USERSDETAIL = '/app/users/:userId',
  ASSOCIATION = '/app/association',
  ASSOCIATIONDETAIL = '/app/association/:associationId',
}

export const AppRoutePathFn = {
  HASVEST_RESULT_DETAIL: (id: number) => `/app/harvest-result/${id}`,
}
