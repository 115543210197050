import React from 'react'
import { Link } from 'react-router-dom'

import { Grid, Paper, Typography, Button, CircularProgress } from '@material-ui/core'
import { GridColDef, DataGrid } from '@material-ui/data-grid'
import { VerifiedUser } from '@material-ui/icons'

import Copyright from '~/components/copyright'
import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { Premium } from '~/enums/enum'
import { useClientsQuery } from '~/store/client/client-service'
import { ClientFilters } from '~/types/client-types'
import { CpfCnpjMask } from '~/utils'

import ClientCardFilter from './client-card-filter'
import { CadastrouEquipe } from './enum'
import strings from './strings'
import { useStyles } from './styles'

const listPageSize = 100

const Client: React.FC = () => {
  const classes = useStyles()
  const [filters, setFilters] = React.useState<ClientFilters | null>(null)
  const [page, setPage] = React.useState(1)

  const { data, isFetching } = useClientsQuery({
    size: listPageSize,
    page,
    filters,
  })

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Agricultor',
      width: 150,
      flex: 0.3,
      filterable: false,
    },
    {
      field: 'documento',
      headerName: 'Documento',
      width: 150,
      flex: 0.2,
      renderCell: (params: any) => (params.value ? CpfCnpjMask(params.value) : '-'),
      filterable: false,
    },
    {
      field: 'origem',
      headerName: 'Origem',
      width: 180,
      flex: 0.15,
      renderCell: (params: any) => (
        <p style={{ color: params.value === 'Protege' ? '#20aee3' : '#ff9041' }}>{params.value}</p>
      ),
      filterable: false,
    },
    {
      field: 'descricao_etapa',
      headerName: 'Etapa',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
    },
    {
      field: 'cadastrou_equipe',
      headerName: 'Equipe',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <p
          style={{
            color: params.value === CadastrouEquipe[1] ? 'green' : 'red',
          }}
        >
          {params.value}
        </p>
      ),
      valueGetter: (params) => CadastrouEquipe[params?.row?.cadastrou_equipe],
    },
    {
      field: 'premium',
      headerName: 'Cliente PlantUP IA',
      width: 150,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params: any) => (
        <p style={{ color: params.row?.premium === '1' ? 'green' : 'red' }}>
          {params.value.toUpperCase()}
        </p>
      ),
      valueGetter: (params) => Premium[params.row?.premium],
    },
    {
      field: 'id',
      headerName: 'Ações',
      description: 'Abre os detalhes do agricultor',
      flex: 0.1,
      minWidth: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params: any) => (
        <Link to={`${AppRoutePath.CLIENTDETAIL.replace(':cliente_id', params.value)}`}>
          <Button variant="contained" color="primary" size="small" endIcon={<VerifiedUser />}>
            {strings.subtitle}
          </Button>
        </Link>
      ),
    },
  ]

  return (
    <Grid item xs={12} className={classes.gridMain}>
      <Paper elevation={0} className={classes.paper}>
        <Grid container item xs={12} className={classes.titleStyle}>
          <Typography variant="h4" color="primary">
            {strings.title}
          </Typography>
        </Grid>

        <Grid container item sm={12} xs={12}>
          <ClientCardFilter onChangeFilter={setFilters} />
        </Grid>

        <Grid container item sm={12} xs={12} className={classes.resumeAuditTable}>
          {isFetching ? (
            <Grid container item sm={12} xs={12} className={classes.positionCircularProgress}>
              <CircularProgress variant="indeterminate" size="3rem" />
            </Grid>
          ) : (
            <DataGrid
              page={page - 1}
              rows={data?.data || []}
              rowCount={data?.totalRowCount || 0}
              columns={columns}
              loading={isFetching}
              localeText={GRID_DEFAULT_LOCALE_TEXT}
              onPageChange={(newPage) => setPage(newPage + 1)}
              pageSize={listPageSize}
              rowsPerPageOptions={[listPageSize]}
              paginationMode="server"
            />
          )}
        </Grid>
      </Paper>
      <Copyright />
    </Grid>
  )
}

export default Client
