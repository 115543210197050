import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Chip, CircularProgress, Grid } from '@material-ui/core'
import { GridColDef, DataGrid } from '@material-ui/data-grid'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { allResults } from '~/constants/data-field'
import { AppRoutePath } from '~/constants/paths'
import { GRID_DEFAULT_LOCALE_TEXT } from '~/constants/translate-data-grid'
import { useValidationQuery } from '~/store/validation/validation-service'
import { useValidationStore } from '~/store/validation/validation-store'
import { IValidationFilters } from '~/types/validation-types'
import { maskInterceptor } from '~/utils/mask-interceptor'

import { useStyles } from '../../style'
import ValidationCardFilter from '../validation-card-filter'

interface IValidationAll {
  pages: string | number
}

const listPageSize = 100

const ValidationAll: React.FC<IValidationAll> = ({ pages }) => {
  const classes = useStyles()
  const [filters, setFilters] = React.useState<IValidationFilters | null>(null)
  const [page, setPage] = React.useState(1)

  const { findClientById, setSafraField } = useValidationStore()

  const { data, isFetching } = useValidationQuery({
    size: listPageSize,
    page,
    filters,
  })

  const filterValidation = data
    ? data.data.filter((item) => item.descricao_situacao !== 'Aprovado')
    : []

  const formatNumber = (number: number) => {
    return number.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  const columns: GridColDef[] = [
    {
      field: 'cliente_nome',
      headerName: 'Agricultor',
      flex: 0.2,
      width: 150,
      minWidth: 120,
      filterable: false,
    },

    {
      field: 'cpf_cnpj_agricultor',
      headerName: 'CPF/CNPJ',
      width: 200,
      filterable: false,
      renderCell: (params) =>
        params?.row?.cpf_cnpj_agricultor.length === 11
          ? maskInterceptor('cpf')(params.row?.cpf_cnpj_agricultor)
          : maskInterceptor('cnpj')(params.row?.cpf_cnpj_agricultor),
    },
    {
      field: 'celular_agricultor',
      headerName: 'Telefone',
      width: 120,
      flex: 0.2,
      filterable: false,
      renderCell: (params) => maskInterceptor('phone')(params.row?.celular_agricultor),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 140,
      flex: 0.2,
      headerAlign: 'center',
      align: 'center',
      minWidth: 140,
      filterable: false,
    },
    {
      field: 'qtde_total',
      headerName: 'Área(ha)',
      width: 120,
      flex: 0.3,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => formatNumber(Number(params.row?.qtde_total)),
      valueGetter: (params) => {
        const value = Number(params.row?.qtde_total)
        return value
      },
    },
    {
      field: 'ultima_data_envio_validacao',
      headerName: 'Data último envio',
      flex: 0.3,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => {
        const date = new Date(params.row?.ultima_data_envio_validacao)
        const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
        return parseDate
      },
      valueGetter: (params) => {
        const date = new Date(params.row?.ultima_data_envio_validacao)
        const parseDate = new Intl.DateTimeFormat('pt-br').format(date)
        return parseDate
      },
    },
    {
      field: 'descricao_situacao',
      headerName: 'Agricultor Safra',
      flex: 0.3,
      minWidth: 110,
      headerAlign: 'center',
      align: 'center',
      filterable: false,

      renderCell: (params) => {
        return (
          <Chip
            id="chips-status"
            label={params.row?.descricao_situacao}
            style={{ backgroundColor: '#FF9041', color: '#fff' }}
          />
        )
      },
    },
    {
      field: 'descricao_alterado_por',
      headerName: 'Alterado por',
      flex: 0.3,
      minWidth: 130,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params) => maskInterceptor('phone')(params.row?.celular),
    },
    {
      field: 'acoes',
      headerName: 'Exibir',
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      renderCell: (params: any) => {
        const parametroId = params ? params.id : params?.row.client_id
        return (
          <Link to={`${AppRoutePath.VALIDATIONDETAIL.replace(':validationId', parametroId)}`}>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#48bbe6',
                color: '#fff',
                fontSize: '10px',
              }}
              onClick={() => {
                findClientById(data?.data, parametroId)
                setSafraField(allResults)
              }}
            >
              <VisibilityIcon />
            </Button>
          </Link>
        )
      },
    },
  ]

  return (
    <>
      <Grid container item sm={12} xs={12}>
        <ValidationCardFilter onChangeFilter={setFilters} />
      </Grid>
      {isFetching ? (
        <Grid container item sm={12} xs={12} className={classes.positionCircularProgress}>
          <CircularProgress variant="indeterminate" size="3rem" />
        </Grid>
      ) : (
        <DataGrid
          page={page - 1}
          rows={data?.data || []}
          rowCount={pages === '0' ? data?.totalRowCount : filterValidation.length}
          columns={columns}
          loading={isFetching}
          localeText={GRID_DEFAULT_LOCALE_TEXT}
          onPageChange={(newPage) => setPage(newPage + 1)}
          pageSize={listPageSize}
          rowsPerPageOptions={[listPageSize]}
          paginationMode="server"
          style={{
            maxHeight: '66vh',
            minHeight: '66vh',
            height: '100%',
          }}
          disableSelectionOnClick
          sortingMode="client"
          getRowId={(row) => row.cliente_id}
        />
      )}
    </>
  )
}

export default ValidationAll
