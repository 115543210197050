/* eslint-disable prettier/prettier */
import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { api } from '~/services/api'
import { GrowCropsFilter, GrowcropsQueryParams, VorietyListProps } from '~/types/grow-crops-types'
import { Page, SourcePage } from '~/types/pagination-types'
import { convertToPageGrowcrops } from '~/utils/functions/functions'

export const useGrowcropsQuery = ({
  size,
  page,
  filters,
}: GrowcropsQueryParams): UseQueryResult<Page<VorietyListProps>> => {
  const fetchGrowcrops = async ({
    size,
    page,
    filters,
  }: {
    size: number
    page: number
    filters: GrowCropsFilter | null
  }): Promise<Page<VorietyListProps>> => {
    const { data }: { data: SourcePage<VorietyListProps> } = await api.get('/variety/list', {
      params: {
        page,
        size,
        culture: filters?.culture,
        name_variety: filters?.name_variety,
        genetic: filters?.genetic,
        tecnologic: filters?.tecnologic,
        maturation: filters?.maturation,
        status: filters?.status,
      },
    })
    return convertToPageGrowcrops(data)
  }

  return useQuery<Page<VorietyListProps>>({
    queryKey: ['growcrops', size, page, filters],
    queryFn: () => fetchGrowcrops({ page, size, filters }),
  })
}
